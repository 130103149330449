




















import get from "@/utilities/get";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import Select2, { DisabledSelection, S2Selection } from "./Select2.vue";
import useEscapeHtml from "@/utilities/useEscapeHtml";

export default defineComponent({
  components: { Select2 },
  emits: ["searchFieldInputted"],
  props: {
    entityName: {
      type: String,
      required: false,
    },
    value: {
      type: Object as PropType<unknown | null>,
      required: false,
    },
    items: {
      type: Array as PropType<unknown[]>,
      default: () => [],
    },
    disabledItems: {
      type: Array as PropType<unknown[]>,
      default: () => [],
    },
    idKey: {
      type: String,
      default: "id",
    },
    textKey: {
      type: String,
      required: true,
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maximumSelectionLength: {
      type: Number,
      default: 2,
    },
    minimumInputLength: {
      type: Number,
      default: 0,
    },
    maximumInputLength: {
      type: Number,
      default: 100,
    },
    templateResult: {
      type: Function,
      default: (data: S2Selection<unknown>) => useEscapeHtml(data.text),
    },
    onlyRebuildData: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    additionalSearchField: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const searchFieldInputted = (input: string) =>
      emit("searchFieldInputted", input);

    return {
      selected: computed<S2Selection<unknown> | undefined>({
        get(): S2Selection<unknown> | undefined {
          return props.value
            ? {
                id: get(props.value, props.idKey),
                text: get(props.value, props.textKey),
                object: props.value,
                html: props.templateResult,
              }
            : undefined;
        },
        set(newValue: S2Selection<unknown> | undefined): void {
          emit("input", newValue?.object);
        },
      }),
      data: computed<(S2Selection<unknown> & DisabledSelection)[]>(() => {
        return props.items.map((element) => {
          return {
            id: get(element, props.idKey),
            text: get(element, props.textKey),
            object: element,
            disabled: props.disabledItems.includes(element),
            html: props.templateResult,
          };
        });
      }),
      searchFieldInputted,
    };
  },
});
