











































































import jQuery from "jquery";
import JQuery from "jquery";
import "select2/dist/js/select2.full.js";
import "select2/dist/js/i18n/da.js";
import "select2/dist/css/select2.min.css";
import get from "@/utilities/get";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import Select2, { OptGroupData, OptionData, SearchOptions } from "select2";
import isArray from "@/utilities/isArray";
import isObject from "@/utilities/isObject";
import useEscapeHtml from "@/utilities/useEscapeHtml";

export type DisabledSelection = {
  disabled: boolean;
};

export class S2Selection<T> {
  id: string;
  text: string;
  object: T;
  html: string;

  constructor(id: string, text: string, object: T, html: string) {
    this.id = String(id);
    this.text = text;
    this.object = object;
    this.html = html;
  }
}

export default defineComponent({
  emits: ["searchFieldInputted"],
  props: {
    tabindex: {
      type: Number,
      default: 0,
    },
    id: String,
    error: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
    },
    entityName: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    allowSearch: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maximumSelectionLength: {
      type: Number,
      default: 2,
    },
    minimumInputLength: {
      type: Number,
      default: 0,
    },
    maximumInputLength: {
      type: Number,
      default: 100,
    },
    isTagged: {
      type: Boolean,
      default: false,
    },
    onlyRebuildData: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array as PropType<S2Selection<unknown>[]>,
      default: [],
    },
    value: null,
    templateResult: {
      type: Function,
      default: (data: { text: any }) => useEscapeHtml(data.text),
    },
    templateSelection: {
      type: Function,
      default: (data: { text: any }) => useEscapeHtml(data.text),
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    additionalSearchField: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { root, emit }) {
    // Component properties
    const $ = jQuery;
    const isOpen = ref(false);
    const select2 = ref(null as HTMLSelectElement | null);

    function onChange() {
      if (select2.value) {
        const jSelect = $(select2.value);

        if (props.multiple) {
          const selections = jSelect.select2("data").map((element) => {
            return props.isTagged
              ? new S2Selection(
                  element.text,
                  element.text,
                  element.text,
                  props.templateResult
                )
              : props.data[element.element.index];
          });
          emit("input", selections);
        } else {
          const selections = jSelect.select2("data").map((element) => {
            return props.data[element.element.index];
          });
          emit("input", selections[0]);
        }
      }
    }

    function setValue(
      element: JQuery<HTMLSelectElement>,
      value: S2Selection<unknown>[] | S2Selection<unknown> | null
    ) {
      if (props.multiple && isArray(value)) {
        element.val(value.map((element) => element.id)).trigger("change");
      } else if (isObject(value) && value != null) {
        element.val(value.id).trigger("change");
      } else {
        element.val("").trigger("change");
      }
    }

    const matchCustom = (
      params: SearchOptions,
      data: OptGroupData | OptionData
    ): Select2.OptGroupData | Select2.OptionData | null => {
      // If there are no search terms, return all of the data
      if (jQuery.trim(params.term) === "") {
        return data;
      }

      // Do not display the item if there is no 'text' property
      if (typeof data.text === "undefined") {
        return null;
      }

      // Do a recursive check for options with children
      if (data.children && data.children.length > 0) {
        // Clone the data object if there are children
        // This is required as we modify the object to remove any non-matches
        var match = $.extend(true, {}, data);

        // Check each child of the option
        for (var c = data.children.length - 1; c >= 0; c--) {
          var child = data.children[c];

          var matches = matchCustom(params, child);

          // If there wasn't a match, remove the object in the array
          if (matches == null) {
            match.children.splice(c, 1);
          }
        }

        // If any children matched, return the new object
        if (match.children.length > 0) {
          return match;
        }

        // If there were no matching children, check just the plain object
        return matchCustom(params, match);
      }

      // `params.term` should be the term that is used for searching
      // `data.text` is the text that is displayed for the data object
      if (
        data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1 ||
        isMatchSearch(params, data)
      ) {
        var modifiedData = jQuery.extend({}, data, true);

        // You can return modified objects from here
        // This includes matching the `children` how you want in nested data sets
        return modifiedData;
      }

      // Return `null` if the term should not be displayed
      return null;
    };

    const settings = computed<Select2.Options>(() => {
      return {
        minimumInputLength: props.minimumInputLength,
        maximumInputLength: props.maximumInputLength,
        matcher: matchCustom,
        theme: "bootstrap",
        width: "100%",
        language: root.$i18n.locale,
        data: props.data ?? [],
        placeholder: props.isLoading
          ? root.$tc("select.loading")
          : !props.placeholder
          ? root
              .$t("select.placeholder", { entityName: props.entityName })
              .toString()
          : props.placeholder,
        allowClear: props.allowClear,
        multiple: props.multiple,
        maximumSelectionLength: props.maximumSelectionLength,
        tags: props.isTagged,
        minimumResultsForSearch: props.allowSearch ? 1 : Infinity,
        tokenSeparators: props.isTagged ? [",", " "] : [],
        escapeMarkup: function (value) {
          return value;
        },
        templateResult: props.templateResult,
        templateSelection: props.templateSelection,
        sorter: function (data) {
          return data.sort(function (a, b) {
            return a.text.localeCompare(b.text);
          });
        },
      };
    });

    function setup(
      element: JQuery<HTMLSelectElement>,
      settings: Select2.Options
    ) {
      element.empty();
      element.off("select2:select select2:unselect");
      element.off("select2:close");
      element.off("select2:open");

      element.select2(settings);
      setValue(element, props.value);

      element.on("select2:select select2:unselect", onChange);
      element.on("select2:close", onClose);
      element.on("select2:open", onOpen);
    }

    function onClose() {
      isOpen.value = false;
      // We emit because popover needs to know if it should react to outside clicks or not
      root.$emit("select2-closed");
    }

    function onOpen() {
      // We emit because popover needs to know if it should react to outside clicks or not
      root.$emit("select2-opened");

      // A hack fix for a bug in select2 with jQuery 3.x
      setTimeout(() => {
        if (props.data.length > 0)
          (
            document?.querySelector(
              ".select2-container--open .select2-search__field"
            ) as HTMLSelectElement
          )?.focus();
      }, 10);

      $(".select2-container--open .select2-search__field").on("input", (e) => {
        setTimeout(() => {
          const inputtedValue = (e.target as HTMLInputElement).value;
          if (inputtedValue.length >= props.minimumInputLength) {
            emit("searchFieldInputted", inputtedValue);
          }
        }, 200);
      });

      isOpen.value = true;
    }

    // Options filter data from search for other field which was passed from component using
    const isMatchSearch = (
      params: SearchOptions,
      data: OptGroupData | OptionData
    ): boolean => {
      let isMatch = false;
      if (props.additionalSearchField.length > 0) {
        props.additionalSearchField.forEach((asf) => {
          var valueOfAddtionSearchField = get(data, "object." + asf);
          if (
            valueOfAddtionSearchField
              .toLowerCase()
              .indexOf(params.term.toLowerCase()) > -1
          ) {
            isMatch = true;
            return;
          }
        });
      }
      return isMatch;
    };

    watch(
      () => props.value,
      (newValue) => {
        if (select2.value) {
          setValue($(select2.value), newValue);
        }
      }
    );

    watch(
      () => settings.value,
      (newValue) => {
        // if new data is passed into the component, we'll use select2 dataAdapter to handle new data instead of rebuilding a whole control
        if (select2.value) {
          if (props.onlyRebuildData) {
            const select2Data = $(select2.value).data("select2");

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const dataAdapter = (select2Data as any).dataAdapter;
            select2Data.options.options.data = newValue.data;

            // remove all options and rebuild options based on new Data
            dataAdapter.$element.find("option").remove();
            dataAdapter.addOptions(dataAdapter.convertToOptions(newValue.data));

            // the select auto selects the first option so we will remove the selected option after rebuilding new data
            dataAdapter.$element.val("");
            dataAdapter.$element.trigger("change");

            select2Data.dropdown.handleSearch();
          } else setup($(select2.value), newValue);
        }
      }
    );

    onMounted(() => {
      if (select2.value) {
        setup($(select2.value), settings.value);
      }

      setTimeout(() => {
        if (props.autoFocus && select2.value) $(select2.value).select2("open");
      }, 400);
    });

    onUnmounted(() => {
      //To prevent visual bug when closing popup.
      setTimeout(() => {
        if (select2.value) $(select2.value).select2("destroy");
      }, 100);
    });

    return { select2, isOpen, settings };
  },
});
